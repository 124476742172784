<template>
  <div style="text-align: center;">
    <p v-if="autoLogin !== 0">
      <span v-if="autoLogin > 0">{{$t('common.logining')</span>
      <span v-else>{{$t('page.login.login_fail')</span>
    </p>
    <Card v-else id="login" :style="box_style">
      <p slot="title">
        <Icon type="ios-person"></Icon>{{$t('page.login.login')}}
      </p>
      <span v-if="false" slot="extra"><router-link to="forgot">{{$t('page.login.forgot')}}</router-link></span>
      <div style="display: table;margin:auto;">
        <div style="display: table-row;">
          <span style="text-align: right; vertical-align: bottom;display: table-cell;">{{$t('page.login.state_code')}}</span>
          <Select style="width: auto;display: table-cell;" v-model="state_code" filterable clearable prefix="ios-globe"
                  placeholder="Select state">
            <OptionGroup v-for="(i,key) in states" :label="key" :key="key">
              <Option v-for="item in i" :value="item.state_code" :label="item.country + ' +' + item.state_code"
                      :key="item.short_name">{{ item.country + ' +' + item.state_code }}<span
                style="display: none">{{ key + item.short_name }}</span></Option>
            </OptionGroup>
          </Select>
        </div>
        <div style="display: table-row;">
          <span style="padding-top: 15px; padding-bottom: 5px; text-align: right; vertical-align: bottom;display: table-cell;">{{$t('page.login.mobile')}}</span>
          <Input style="width: auto;display: table-cell;" v-model="mobile" maxlength="20" autofocus prefix="ios-call"
                 placeholder="Enter phone"/>
        </div>
        <div style="display: table-row;">
          <span style="padding-top: 5px; padding-bottom: 5px; text-align: right; vertical-align: bottom;display: table-cell;">{{$t('page.login.pwd')}}：</span>
          <Input style="width: auto;display: table-cell;" v-model="password" maxlength="15" prefix="ios-key"
                 type="password"></Input>
        </div>
      </div>
      <Divider dashed size="small"></Divider>
      <Button @click="login" type="primary">{{$t('page.login.login')}}</Button>
    </Card>
  </div>
</template>
<script>
  import { httpGet, httpPost } from '@/api/data';

  export default {
    name: 'Login',
    components: {
    },
    data () {
      return {
        logining: false,
        autoLogin: 0,
        states: [],
        state_code: '',
        mobile: '',
        password: '',

        box_style: ''
      }
    },
    methods: {
      login () {
        if (this.logining) {
          return;
        }
        if (!this.state_code) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: this.$t('page.login.no_statecode')
          });
          return;
        }
        this.mobile = this.mobile.trim();
        if (this.mobile.length === 0) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: this.$t('page.login.no_mobile')
          });
          return;
        }
        this.password = this.password.trim();
        if (this.password.length === 0) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: this.$t('page.login.no_pwd')
          });
          return;
        }
        window.localStorage.setItem('login_info', JSON.stringify({
          account: this.mobile,
          code: this.state_code
        }));

        this.logining = true;
        this.$Spin.show();
        httpPost('/v1/user/login', {
          state_code: this.state_code,
          mobile: this.mobile,
          password: this.password
        }).then(res => {
          this.loginOK(res);
        }).catch(() => {
          this.$Spin.hide();
          this.logining = false;
        });
      },
      loginOK (user) {
        // 立即获取用户信息
        this.$store.commit('login', user);
        this.$store.commit('readUserInfo', true);
        httpGet('/v1/bbs/user').then(res => {
          this.$store.commit('saveUserInfo', res);
          this.onUserInfo();
        }).catch(() => {
          // 当视作登录不成功
          this.$store.commit('login', null);
        }).finally(() => {
          this.$store.commit('readUserInfo', false);
          this.logining = false;
          this.$Spin.hide();
        });
      },
      onUserInfo () {
        let route = this.$route.params.target;
        if (route && typeof route !== 'string') {
          if (!route.name && !route.path) {
            route = this.$route.params.from;
          }
        }
        if (route) {
          this.$router.push(route);
        } else {
          this.$router.go(-1);
        }
      }
    },
    created () {
      if (window.screen.width >= 730) {
        this.box_style += 'width: auto; padding-left:10px;padding-right:10px; display: inline-block;';
      }
    },
    mounted () {
      const auth = this.$route.query.auth;
      if (auth) {
        this.autoLogin = 1;
        httpPost('/v1/user/autologin', {
          code: auth
        }).then(res => {
          this.autoLogin = 0;
          this.loginOK(res);
        }).catch((err) => {
          if (err.code < 1000) {
            this.$router.go(-1);
          } else {
            this.autoLogin = -1;
          }
        });
        return;
      }
      // 监测回车
      document.onkeydown = e => {
        if (e.key === 'Enter') {
          if (e.target === document.body || e.target.type === 'password') {
            this.login();
          }
        }
      };
      let info = window.localStorage.getItem('login_info');
      if (info) {
        info = JSON.parse(info);
        if (info) {
          this.state_code = info.code;
          this.mobile = info.account;
        }
      }
      if (this.$store.state.states) {
        this.states = this.$store.state.states;
      } else {
        // 地区码
        httpGet('/states').then(res => {
          this.states = res.detail;
          this.$store.commit('saveStates', res.detail);
        }).catch(() => {
        });
      }
    }
  };
</script>

<style scoped>

</style>
